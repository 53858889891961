<template>
    <div id="profile" class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Perfil</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card card-primary card-outline">
                            <div class="card-body box-profile">
                                <div class="text-center d-flex">
                                    <img class="profile-user-img img-fluid img-circle" :src="corrector.avatar" alt="User profile picture">
                                    <div class="text-center">
                                        <input type="file" class="form-control" id="updated-photo"  style="display: none" @change="changePhoto($event)">
                                        <label class="btn btn-xs btn-warning btn-change-photo" for="updated-photo">
                                            <font-awesome-icon icon="camera" class="nav-icon" size="1x"/> Trocar foto
                                        </label>
                                    </div>
                                </div>
                                <h3 class="profile-username text-center mb-4">{{ corrector.name }}</h3>
                            </div>
                        </div>
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Sobre mim</h3>
                            </div>
                            <div class="card-body">
                                <strong><font-awesome-icon icon="certificate" /> Especialista em</strong>
                                <select id="especialization" class="form-control select2" multiple></select>
                                <hr>
                                <div id="education-area">
                                    <div class="about-content">
                                        <strong><font-awesome-icon icon="graduation-cap" /> Formação Acadêmica</strong>
                                        <div style="margin-right: 0px; margin-left: auto;">
                                            <span class="badge bg-blue save-education" style="display: none;">Salvar</span>
                                            <span class="badge bg-green saved-education" style="display: none;">Salvo!</span>
                                            <button class="btn btn-xs btn-warning" @click="editEducation()" v-if="!editingEducation">
                                                <font-awesome-icon icon="pencil-alt" /> Editar
                                            </button>
                                            <button class="btn btn-xs btn-success" @click="saveEducation()" v-if="editingEducation">
                                                <font-awesome-icon icon="save" /> Salvar
                                            </button>
                                            <span class="badge bg-default edit-education" style="display: none;">Editar</span>
                                            <span class="badge bg-yellow saving-education" style="display: none;">Salvando ...</span>
                                            <span class="badge bg-red error-saving-education" style="display: none;">Erro ao salvar. Tente novamente</span>
                                        </div>
                                    </div>
                                    <div id="education" class="text-muted justify active" :contenteditable="`${editingEducation}`" @input="inputEducation">{{ education }}</div>
                                </div>
                                <hr>
                                <div id="curriculo-area">
                                    <div class="about-content">
                                        <strong><font-awesome-icon icon="user-edit" /> Currículo</strong>
                                        <div style="margin-right: 0px; margin-left: auto;">
                                            <span class="badge bg-blue save-curriculo" style="display: none;">Salvar</span>
                                            <span class="badge bg-green saved-curriculo" style="display: none;">Salvo!</span>
                                            <span class="badge bg-default edit-curriculo" style="display: none;">Editar</span>
                                            <button class="btn btn-xs btn-warning" @click="editCurriculum()" v-if="!editingCurriculum">
                                                <font-awesome-icon icon="pencil-alt" /> Editar
                                            </button>
                                            <button class="btn btn-xs btn-success" @click="saveCurriculum()" v-if="editingCurriculum">
                                                <font-awesome-icon icon="save" /> Salvar
                                            </button>
                                            <span class="badge bg-yellow saving-curriculo" style="display: none;">Salvando ...</span>
                                            <span class="badge bg-red error-saving-curriculo" style="display: none;">Erro ao salvar. Tente novamente</span>
                                        </div>
                                    </div>
                                    <div id="curriculum" class="text-muted justify" :contenteditable="`${editingCurriculum}`" @input="inputCurriculum">{{ curriculum }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">Termos de Uso</h3>
                            </div>
                            <div class="card-body">
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-header p-2">
                                <ul class="nav nav-pills">
                                    <li class="nav-item">
                                        <button :class="[isShowingResetPassword ? 'btn' : 'btn btn-primary']" @click="showConfigurations">Configurações</button>
                                    </li>
                                    <li class="nav-item">
                                        <button :class="[isShowingResetPassword ? 'btn btn-primary' : 'btn']" @click="showResetPassword">Alterar Senha</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    <div class="tab-pane" :class="[isShowingResetPassword ? '' : 'active']">
                                        <div class="form-group row">
                                            <label for="inputName" class="col-sm-2 col-form-label">Nome</label>
                                            <div class="col-sm-10">
                                                <input type="text" class="form-control" id="inputName" placeholder="Nome" v-model="correctorData.name">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">Email</label>
                                            <div class="col-sm-10">
                                                <input type="email" class="form-control" placeholder="Email" v-model="correctorData.email">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-2 col-form-label">Email do PagSeguro</label>
                                            <div class="col-sm-10">
                                                <input type="email" class="form-control" placeholder="Email" v-model="correctorData.emailPagSeguro">
                                                <p class="text-muted">Associe sua conta do PagSeguro com o Redação Online para uma experiência de pagamento mais simples.</p>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="offset-sm-2 col-sm-10">
                                                <button class="btn btn-success" @click="saveProfileData()">Salvar</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" :class="[isShowingResetPassword ? 'active' : '']">
                                        <div class="form-group row">
                                            <label for="password" class="col-sm-2 col-form-label">Senha Atual</label>
                                            <div class="col-sm-10">
                                                <input type="password" class="form-control" placeholder="Digite a senha atual" v-model="password.password" autocomplete="off">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="password" class="col-sm-2 col-form-label">Senha Nova</label>
                                            <div class="col-sm-10">
                                                <input type="password" class="form-control" placeholder="Digite a senha nova" v-model="password.new_password">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="password" class="col-sm-2 col-form-label">Repetir Senha Nova</label>
                                            <div class="col-sm-10">
                                                <input type="password" class="form-control" placeholder="Repita a senha nova" v-model="password.new_password_confirmation">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="offset-sm-2 col-sm-10">
                                                <button class="btn btn-success" @click="changePassword()">Alterar senha</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import $ from 'jquery'
import Toastr from "admin-lte/plugins/toastr/toastr.min.js"
import "admin-lte/plugins/select2/js/select2.min.js";
import "admin-lte/plugins/toastr/toastr.min.css"
import api from '../services/api' 
import {
    CORRECTOR_PROFILE_INDEX, 
    CORRECTOR_UPDATE_CURRICULUM, 
    CORRECTOR_UPDATE_EDUCATION, 
    CORRECTOR_UPDATE_DATA, 
    CORRECTOR_CHANGE_PASSWORD, 
    CORRECTOR_CHANGE_AVATAR,
    ADD_SPECIALIZATION,
    REMOVE_SPECIALIZATION
} from '../constants/api'

export default {
    name: 'Profile',
    data() {
        return {
            isShowingResetPassword: false,
            corrector: JSON.parse(localStorage.getItem('corrector')),
            editingEducation: false,
            editingCurriculum: false,
            education: '',
            curriculum: '',
            correctorData: {
                name: '',
                email: '',
                emailPagSeguro: ''
            },
            password: {
                password: '',
                new_password: '',
                new_password_confirmation: '',
            }
        }
    },
    mounted() {
        $(document).ready(function() {
            $('.select2').select2();

            $('#especialization').on('select2:select', function (e) {
                document.querySelector('#profile').__vueParentComponent.ctx.addSpecialization(e.params.data.id)
            });

            $('#especialization').on('select2:unselect', function (e) {
                document.querySelector('#profile').__vueParentComponent.ctx.removeSpecialization(e.params.data.id)
            });
        });
    },
    created() {
        api.get(CORRECTOR_PROFILE_INDEX).then(({ data }) => {

            this.education = data.education
            this.curriculum = data.curriculum
            this.correctorData.name = data.corrector.name
            this.correctorData.email = data.corrector.email
            this.correctorData.emailPagSeguro = data.corrector.pagseguro_email

            var especializations = $('#especialization').html('');

            data.specialization.forEach(category => {

                let group = $("<optgroup></optgroup>").attr('label', category.name)

                category.especializations.forEach(especialization => {
                    group.append($("<option></option>").attr("value", especialization.id).text(especialization.name));
                })

                especializations.append(group)
            });

            let selectedSpecializations = []

            data.corrector.especializations.forEach(especialization => {
                selectedSpecializations.push(especialization.especialization_id)
            })

            $('#especialization').select2({width:'100%'}).val(selectedSpecializations).trigger('change')

        })
    },
    methods: {
        showConfigurations() {
            this.isShowingResetPassword = false
        },
        showResetPassword() {
            this.isShowingResetPassword = true
        },
        inputEducation(e) {
            this.education = e.target.innerText
        },
        editEducation() {
            this.editingEducation = true
        },
        saveEducation() {

            api.post(CORRECTOR_UPDATE_EDUCATION, { education: this.education }).then((response) => {

                this.editingEducation = false
                Toastr.success(response.data.message[0], "")

            }).catch(function(error) {

                if(error.response.status == 422) {
                    Toastr.warning(error.response.data.errors.education[0], "")
                } else {
                    Toastr.error(error.response.data.message[0], "")
                }

            })
        },
        inputCurriculum(e) {
            this.curriculum = e.target.innerText
        },
        editCurriculum() {
            this.editingCurriculum = true
        },
        saveCurriculum() {

            api.post(CORRECTOR_UPDATE_CURRICULUM, { curriculum: this.curriculum }).then((response) => {

                this.editingCurriculum = false
                Toastr.success(response.data.message[0], "")

            }).catch(function(error) {

                if(error.response.status == 422) {
                    Toastr.warning(error.response.data.errors.curriculum[0], "")
                } else {
                    Toastr.error(error.response.data.message[0], "")
                }

            })
        },
        saveProfileData() {

            api.post(CORRECTOR_UPDATE_DATA, this.correctorData).then((response) => {

                this.editingCurriculum = false
                Toastr.success(response.data.message[0], "")

            }).catch(function(error) {

                var status = error.response.status

                if(status == 422) {

                    var errors = error.response.data.errors

                    for (var prop in errors) {
                        Toastr.warning(errors[prop], "")
                    }

                } else {
                    Toastr.error(error.response.data.message[0], "")
                }
            })
        },
        changePassword() {

            api.post(CORRECTOR_CHANGE_PASSWORD, this.password).then((response) => {
                Toastr.success(response.data.message[0], "")

            }).catch(function(error) {

                var status = error.response.status

                if(status == 422) {

                    var errors = error.response.data.errors

                    for (var prop in errors) {

                        for (var message in errors[prop]) {
                            Toastr.warning(errors[prop][message], "")
                        }
                    }

                } else {
                    Toastr.error(error.response.data.message[0], "")
                }
            })
        },
        changePhoto(e) {

            let formData = new FormData();
            formData.append('avatar', e.target.files[0]);

            api.post(CORRECTOR_CHANGE_AVATAR, formData).then((response) => {

                this.editingCurriculum = false
                Toastr.success(response.data.message[0], "")
                let corrector = JSON.parse(localStorage.getItem('corrector'))
                corrector.avatar = response.data.avatar[0]
                localStorage.setItem('corrector', JSON.stringify(corrector))

            }).catch(function(error) {

                var status = error.response.status

                if(status == 422) {

                    var errors = error.response.data.errors

                    for (var prop in errors) {

                        for (var message in errors[prop]) {
                            Toastr.warning(errors[prop][message], "")
                        }
                    }

                } else {
                    Toastr.error(error.response.data.message[0], "")
                }
            })
        },

        addSpecialization(id) {
            api.post(ADD_SPECIALIZATION, { id : id }).then((response) => {
                Toastr.success(response.data.message[0], "")
            })
        },

        removeSpecialization(id) {
            api.post(REMOVE_SPECIALIZATION, { id : id }).then((response) => {
                Toastr.success(response.data.message[0], "")
            })
        }
    },
}
</script>

<style scoped>

    .about-content {
        display: flex;
        margin-bottom: 5px;
    }

    #education[contenteditable="true"], #curriculum[contenteditable="true"] {
        color: #495057;
        background-color: #fff;
        outline: 0;
        border-radius: 0.25rem;
        border: solid 1px #80bdff;
        box-shadow: inset 0 0 0 transparent;
    }

    .box-profile > div {
        flex-direction: column;
    }

    .btn-change-photo {
        width: fit-content;
        align-self: center;
        margin-top: 5px;
    }

    #updated-photo {
        display: none;
    }

</style>
